import {
  FETCH_CURRENT_USER_STARTED,
  FETCH_CURRENT_USER_SUCCESS,
  FETCH_CURRENT_USER_FAILURE,
  LOGIN_USER_STARTED,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGOUT_USER,
  CLEAR_AUTH_STATE
} from "../actions/types";

const initialState = {
  loading: false,
  data: {},
  error: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_CURRENT_USER_STARTED:
      return {
        ...state,
        loading: true
      };
    case FETCH_CURRENT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload
      };
    case FETCH_CURRENT_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case LOGIN_USER_STARTED:
      return {
        ...state,
        loading: true
      };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload
      };
    case LOGIN_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case LOGOUT_USER:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null
      };
    case CLEAR_AUTH_STATE:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null
      };
    default:
      return state;
  }
}
