export const FETCH_CURRENT_USER_STARTED = "fetch_current_user_started";
export const FETCH_CURRENT_USER_SUCCESS = "fetch_current_user_success";
export const FETCH_CURRENT_USER_FAILURE = "fetch_current_user_failure";

export const LOGIN_USER_STARTED = "login_user_started";
export const LOGIN_USER_SUCCESS = "login_user_success";
export const LOGIN_USER_FAILURE = "login_user_failure";

export const LOGOUT_USER = "logout_user";

export const CLEAR_AUTH_STATE = "clear_auth_state";

export const FETCH_ACCOUNTS_STARTED = "fetch_accounts_started";
export const FETCH_ACCOUNTS_SUCCESS = "fetch_accounts_success";
export const FETCH_ACCOUNTS_FAILURE = "fetch_accounts_failure";

export const FETCH_USERS_STARTED = "fetch_users_started";
export const FETCH_USERS_SUCCESS = "fetch_users_success";
export const FETCH_USERS_FAILURE = "fetch_users_failure";

export const FETCH_KEYWORDS_STARTED = "fetch_keywords_started";
export const FETCH_KEYWORDS_SUCCESS = "fetch_keywords_success";
export const FETCH_KEYWORDS_FAILURE = "fetch_keywords_failure";

export const CREATE_ACCOUNTS_STARTED = "create_accounts_started";
export const CREATE_ACCOUNTS_SUCCESS = "create_accounts_success";
export const CREATE_ACCOUNTS_FAILURE = "create_accounts_failure";

export const DELETE_ACCOUNTS_STARTED = "delete_accounts_started";
export const DELETE_ACCOUNTS_SUCCESS = "delete_accounts_success";
export const DELETE_ACCOUNTS_FAILURE = "delete_accounts_failure";

export const UPDATE_ACCOUNTS_STARTED = "update_accounts_started";
export const UPDATE_ACCOUNTS_SUCCESS = "update_accounts_success";
export const UPDATE_ACCOUNTS_FAILURE = "update_accounts_failure";
