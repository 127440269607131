import {
  FETCH_KEYWORDS_STARTED,
  FETCH_KEYWORDS_SUCCESS,
  FETCH_KEYWORDS_FAILURE
} from "../actions/types";

const initialKeywordsState = {
  loading: false,
  data: [],
  error: null
};

export const keywords = (state = initialKeywordsState, action) => {
  switch (action.type) {
    case FETCH_KEYWORDS_STARTED:
      return {
        ...state,
        loading: true
      };
    case FETCH_KEYWORDS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload
      };
    case FETCH_KEYWORDS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};
