import React from "react";
import { connect } from "react-redux";
import { Table, Drawer, Icon } from "antd";
import isEmpty from "lodash.isempty";
import moment from "moment";

import { fetchUsers, fetchKeywords } from "../../actions";

class DetailedView extends React.Component {
  componentDidMount() {
    const { record } = this.props;
    if (record && record._id) {
      this.props.fetchUsers(record._id);
      this.props.fetchKeywords(record._id);
    }
  }

  render() {
    const { onClose, visible, users, keywords } = this.props;
    console.log(this.props);

    const userColumns = [
      {
        title: "Name",
        dataIndex: "_user.local.name",
        key: "_user.local.name"
      },
      {
        title: "Email",
        dataIndex: "_user.local.email",
        key: "_user.local.email"
      },
      {
        title: "Access",
        dataIndex: "access",
        key: "access"
      },
      {
        title: "Created",
        key: "createdAt",
        render: (text, record) => (
          <div>{moment(record.createdAt).fromNow()}</div>
        )
      }
    ];

    const keywordColumns = [
      {
        title: "Keyword",
        dataIndex: "keyword",
        key: "keyword"
      },
      {
        title: "Whole Word Only",
        dataIndex: "wholeWordOnly",
        render: (text, record) => (
          <div>
            <Icon
              type={record.wholeWordOnly ? "check-square" : "close-square"}
              style={
                record.wholeWordOnly
                  ? { color: "blue", fontSize: "20px" }
                  : { color: "red", fontSize: "20px" }
              }
            />
          </div>
        )
      },
      {
        title: "Created",
        key: "createdAt",
        render: (text, record) => (
          <div>{moment(record.createdAt).fromNow()}</div>
        )
      }
    ];

    if (users.loading || keywords.loading) {
      return null;
    } else
      return (
        <Drawer
          width={640}
          placement="right"
          closable={false}
          onClose={onClose}
          visible={visible}
        >
          <h3 style={{ marginTop: "20px" }}>Users</h3>
          <Table
            dataSource={users.data}
            columns={userColumns}
            showHeader={true}
            rowKey={record => record._id}
          />
          <h3 style={{ marginTop: "20px" }}>Tracked Keywords</h3>
          <Table
            dataSource={keywords.data}
            columns={keywordColumns}
            showHeader={true}
            rowKey={record => record._id}
          />
        </Drawer>
      );
  }
}

const mapStateToProps = ({ users, keywords }) => ({
  users,
  keywords
});

export default connect(
  mapStateToProps,
  { fetchUsers, fetchKeywords }
)(DetailedView);
