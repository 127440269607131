import axios from "axios";
import {
  FETCH_ACCOUNTS_STARTED,
  FETCH_ACCOUNTS_SUCCESS,
  FETCH_ACCOUNTS_FAILURE,
  FETCH_USERS_STARTED,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  FETCH_KEYWORDS_STARTED,
  FETCH_KEYWORDS_SUCCESS,
  FETCH_KEYWORDS_FAILURE
} from "./types";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchAccounts = () => async dispatch => {
  try {
    dispatch({ type: FETCH_ACCOUNTS_STARTED });
    const res = await axios.get(`${API_BASE_URL}/api/v1/admin/account`, {
      withCredentials: true
    });
    let { accounts } = res.data;
    dispatch({ type: FETCH_ACCOUNTS_SUCCESS, payload: accounts });
  } catch (error) {
    dispatch({
      type: FETCH_ACCOUNTS_FAILURE,
      payload: error.response.data
    });
  }
};

export const fetchUsers = id => async dispatch => {
  try {
    dispatch({ type: FETCH_USERS_STARTED });
    const res = await axios.get(
      `${API_BASE_URL}/api/v1/admin/account/${id}/users`,
      {
        withCredentials: true
      }
    );
    let { users } = res.data;
    dispatch({ type: FETCH_USERS_SUCCESS, payload: users });
  } catch (error) {
    dispatch({
      type: FETCH_USERS_FAILURE,
      payload: error.response.data
    });
  }
};


export const fetchKeywords = id => async dispatch => {
    try {
      dispatch({ type: FETCH_KEYWORDS_STARTED });
      const res = await axios.get(
        `${API_BASE_URL}/api/v1/admin/account/${id}/keywords`,
        {
          withCredentials: true
        }
      );
      let { keywords } = res.data;
      dispatch({ type: FETCH_KEYWORDS_SUCCESS, payload: keywords });
    } catch (error) {
      dispatch({
        type: FETCH_KEYWORDS_FAILURE,
        payload: error.response.data
      });
    }
  };