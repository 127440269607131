import { combineReducers } from "redux";
import auth from "./authReducers";
import { accounts, users } from "./accountReducers";
import { keywords } from "./keywordReducers";

export default combineReducers({
  auth,
  accounts,
  users,
  keywords
});
